export *  from './src/PaymentsService'
export {PeachyApiService} from './src/PeachyApiService'
export type {RestApi} from './src/PeachyApiService'
export * from './src/CouponService'

        import * as me from '@peachy/payments-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src1272174791/src/codestar-connections.eu-west-2.amazonaws.com/git-http/879656270406/eu-west-2/5886de9c-5c2f-4d33-b265-d0ba742d8141/peachy-punnet/peachy-mono-repo/comp-kit/payments/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/payments-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    